<template>
	<div class="skeleton-wrapper">
		<slot/>
		<span v-show="isVisible" class="skeleton-loader">
            <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </span>
	</div>
</template>
<script>
export default {
	props: {
		isVisible: Boolean
	}
}
</script>
<style lang="scss" scoped>
.skeleton-wrapper {
	position: relative;
    height: 100%;
}
.skeleton-loader {
	position: absolute;
	display: flex;
    justify-content: center;
    align-items: center;
	top: 0%;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 3px;
    background-color: $gray-300;
	z-index: $level1;
    .lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  background: $secondary;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
}
@keyframes pulse-bg {
	0% { background-color: $gray-200; }
	50% { background-color: $gray-300; }
	100% { background-color: $gray-200; }
}
</style>

<template>
	<section class="section-wrapper" v-background="value.background.path">
		<grid-template cols="4">
			<template #title>
				<div v-animate="`slideLeft`">
					<base-heading :heading="`${prefix}.title`" :subheading="`${prefix}.subtitle`"/>
				</div>
			</template>
			<skeleton-loader class=" article" v-for="(article, i) in articles" :key="i"  :isVisible="loading" v-animate="`opacity`">
				<base-article
					:article="article"
					:prefix="prefix"
				/>
			</skeleton-loader>
			<template #buttons>
				<cms-multi-section :value="`${prefix}.section_content`" v-animate="`opacity`"/>
			</template>
		</grid-template>
	</section>
</template>

<script>
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'
import { isPageFromNull } from 'utils/ssrCheck'
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import CmsMultiSection from '~/cms/front/components/CmsMultiSection/CmsMultiSection.vue'
import BaseArticle from '../../components/article/BaseArticle.vue'
export default {
	async prefetch () {
		if (process.server) {
			await this.fetchArticles()

			this.$app.page.componentsData.newest_blog_posts = {}
			this.$app.page.componentsData.newest_blog_posts.articles = this.articles
		}
	},
	data () {
		return {
			articles: Array(4).fill({}),
			loading: true
		}
	},
	components: {
		BaseArticle,
		SkeletonLoader,
		GridTemplate,
		BaseHeading,
		CmsMultiSection
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		async fetchArticles () {
			const data = await this.$app.getService('rext').getArticles({ take: 4 })
			if (data.error) return this.articles
			this.articles = data
			this.timeout()
		},
		recreateDataFromComponentsData () {
			this.articles = this.$app.page.componentsData.newest_blog_posts.articles.slice(0, 5)
			this.timeout()
		},
		timeout () {
			setTimeout(() => {
				this.loading = false
			}, 500)
		}
	},
	mounted () {
		if (isPageFromNull(this.$app)) {
			this.recreateDataFromComponentsData()
		} else {
			this.fetchArticles()
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    padding: 4rem 0;
    @include media-breakpoint-up(xl) {
        padding: 8rem 0;
    }
}
.article {
	width: 100%;
	min-height: 214px;
	& ::v-deep .article {
		height: 100%;
		@include media-breakpoint-landscape() {
			max-height: none;
		}
	}
	@include media-breakpoint-up(xl) {
		& ::v-deep .article {
			max-height: none;
		}
	}
}
.articles-wrapper {
	width: 100%;
	padding: 0 1.5rem;
	margin-bottom: 4rem;
	@include media-breakpoint-up(md) {
		display: flex;
	}
	@include media-breakpoint-up(xl) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 214px);
		grid-gap: 30px;
		margin-bottom: 8rem;
	}
}

</style>

<template>
	<base-link class="article" to="article" :title="article.title" :params="{alias: article.alias}">
		<div class="article-img-wrapper">
			<cms-image
				v-if="article.thumbnail"
				class="article-image"
				lazy
				:src="article.thumbnail"
				:base="{width: 345, height: 220}"
				:sm="{width: 500, height: 120}"
				:md="{width: 320, height: 220}"
				:xl="{width: 330, height: 220}"
			/>

		</div>
		<client-only>
			<div class="article-preview-data">
				<base-font class="article-title" weight="bold" variant="small-heading" :tag="homepage ? 'h3' : 'h2'">{{article.title}}</base-font>

				<div class="article-post-preview" v-html="article.introduction"></div>
				<base-button class="article-btn" type="primary" icon="info" :title="article.title">
					{{langs.blog_button_text}}
				</base-button>
			</div>
		</client-only>
	</base-link>
</template>
<script>
import ClientOnly from 'vue-client-only'

export default {
	components: {
		ClientOnly
	},
	props: {
		article: [Object, Number],
		prefix: String
	},
	computed: {
		langs () {
			return this.$app.translator.get('articles')
		},
		homepage () {
			const routeAlias = this.$app.page.route.meta.alias
			return routeAlias === 'homepage'
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}
</script>
<style lang="scss" scoped>
.article {
	display: flex;
    flex-direction: column;
	background-color: $white;
	text-decoration: none;
	&-image {
		::v-deep .base-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.article-preview-data {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 2rem 2rem 4rem;
	}
	.article-post-preview {
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		overflow: hidden;
        text-transform: initial;
		color: $primary;
		margin-bottom: 4rem;
		line-height: 2.6rem;
        font-weight: 400;
		@include media-breakpoint-up(lg) {
			margin-bottom: 3rem;
		}
	}
	::v-deep.article-title {
		margin-bottom: 3rem;
	}
	::v-deep.article-btn {
		margin-top: auto;
		margin: auto auto 0;
        @include media-breakpoint-up(xxl) {
            padding: 20px 30px;
        }
	}
}
</style>
